import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import VideoList from "../screens/resources/videos/videos-list"

const Videos = () => {
  return (
    <React.Fragment>
      <VideoList />
    </React.Fragment>
  )
}
export default Videos

export const Head = () => (
  <Seo
        title="Videos"
        description="Browse our collection of videos and promos."
        image="/meta/videos.jpg"
  />
)
